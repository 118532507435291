import { useState, useEffect, useCallback } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Spinner,
  Center,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { getTournamentplayersFromTournament } from "../../service/tournamentplayer";
import FontSize from "../../stylingObjects/FontSize";
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import TournamentPlayer from "../../type/TournamentPlayer";

interface Props {
  // tournament_id: number | null;
}

// harcoded tournament_id = 1
const tournament_id = 5;

const Matches: React.FC<Props> = () => {
  const [tournamentplayers, setTournamentplayers] = useState<
    TournamentPlayer[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [isAdminMode, setIsAdminMode] = useState<boolean>(false);

  const navigate = useNavigate();

  const fetchTournamentplayers = useCallback(async () => {
    if (!isAdminMode) {
      console.log("User is not an admin, not fetching tournament players");
      return;
    }

    try {
      setLoading(true);
      if (tournament_id) {
        const fetchedTournamentplayers =
          await getTournamentplayersFromTournament(tournament_id);
        setTournamentplayers(fetchedTournamentplayers);
      } else {
        setTournamentplayers([]);
      }
    } catch (error: any) {
      console.error("Error fetching matches:", error.message);
    } finally {
      setLoading(false);
    }
  }, [isAdminMode]);

  // check if user is admin
  const checkUserAdminRole = useCallback(() => {
    // check if user is admin: get localstorage user, check if 'Roles' contains 'ADMIN' or 'TOURNAMENTADMIN'
    const user = JSON.parse(localStorage.getItem("User") || "null");
    if (user) {
      console.log("user roles:", user.roles);
      const validRoles = ["ADMIN", "TOURNAMENTADMIN"];
      if (user.roles.some((role: string) => validRoles.includes(role))) {
        setIsAdminMode(true);
        console.log("user is an admin");
      } else {
        setIsAdminMode(false);
        console.log("user is not an admin");
      }
    } else {
      console.log("no user found");
      console.log("isAdminMode:", isAdminMode);
    }
  }, [isAdminMode]);

  useEffect(() => {
    checkUserAdminRole();
    fetchTournamentplayers();
  }, [checkUserAdminRole, fetchTournamentplayers]);

  return (
    <>
      <VStack>
        {loading && <Spinner color="red" />}
        {!loading && tournamentplayers.length === 0 && (
          <Text color="red">Geen spelers gevonden voor dit tournament</Text>
        )}
        {!loading && tournamentplayers.length > 0 && (
          <Table color="green" fontSize={{ ...FontSize.header }} size="sm">
            <Thead>
              <Tr>
                <Th>
                  <Center>
                    <Text
                      color="green"
                      fontSize={{ ...FontSize.header }}
                      fontFamily={"Arial"}
                      fontWeight={900}
                    >
                      Speler_id
                    </Text>
                  </Center>
                </Th>
                <Th>
                  <Center>
                    <Text
                      color="green"
                      fontSize={{ ...FontSize.header }}
                      fontFamily={"Arial"}
                      fontWeight={900}
                    >
                      Voornaam
                    </Text>
                  </Center>
                </Th>
                <Th>
                  <Center>
                    <Text
                      color="green"
                      fontSize={{ ...FontSize.header }}
                      fontFamily={"Arial"}
                      fontWeight={900}
                    >
                      Familienaam
                    </Text>
                  </Center>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {tournamentplayers.map((tournamentplayer) => (
                <Tr key={tournamentplayer.tournamentPlayer_id}>
                  <Td>
                    <Center>
                      <Text
                        fontSize={{ ...FontSize.header }}
                        fontFamily={"Arial"}
                        fontWeight={900}
                      >
                        {tournamentplayer.tournamentPlayer_id}
                      </Text>
                    </Center>
                  </Td>
                  <Td>
                    <Center>
                      <Text
                        fontSize={{ ...FontSize.header }}
                        fontFamily={"Arial"}
                        fontWeight={900}
                      >
                        {tournamentplayer.firstname}
                      </Text>
                    </Center>
                  </Td>
                  <Td>
                    <Center>
                      <Text
                        fontSize={{ ...FontSize.header }}
                        fontFamily={"Arial"}
                        fontWeight={900}
                      >
                        {tournamentplayer.lastname}
                      </Text>
                    </Center>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </VStack>
    </>
  );
};

export default Matches;
